import { Link } from "gatsby"
import React from "react"

const Footer = () => (
  <footer className="pb-6 pt-12 mt-20 px-4 lg:px-6 relative z-1 grid grid-cols-12">
    <div className="col-span-full lg:col-span-6 flex flex-col space-y-6 items-start">
      <Link to={"/chymeia"} className="default-link">
        Chymeia
      </Link>
      <Link to={"/impressum"} className="default-link">
        Impressum
      </Link>
      <Link to={"/datenschutz"} className="default-link">
        Datenschutz
      </Link>
    </div>
  </footer>
)

Footer.propTypes = {}

Footer.defaultProps = {}

export default Footer
