/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"
import { useEffect, useState, useMemo } from "react"
import Footer from "./footer"

const Layout = ({ children }) => {
  const [height, setHeight] = useState(0)
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      allImageSharp(
        filter: { fluid: { originalName: { glob: "gold*.png" } } }
      ) {
        edges {
          node {
            fluid(maxWidth: 300) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    setHeight(
      Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      )
    )
  }, [])

  const goldImages = useMemo(() => {
    return data.allImageSharp.edges
      .map(edge => edge.node)
      .map(img => ({
        fluid: img.fluid,
        left: `${Math.random() * 95}%`,
        top: `${Math.random() * height - 50}px`,
      }))
  }, [data.allImageSharp.edges, height])
  return (
    <>
      <div className="flex flex-col relative bg-gold-50 text-black-900 overflow-hidden">
        <div className="secondaryBackground w-1/2 bg-white h-full absolute left-0 top-0 -z-1"></div>
        <main className="w-full">
          <div className="goldSpecs w-full h-full absolute">
            {goldImages.map(img => {
              return (
                <Img
                  fluid={img.fluid}
                  loading="eager"
                  key={img.fluid.originalName}
                  className="absolute  z-1"
                  style={{
                    left: img.left,
                    top: img.top,
                    width: "50px",
                    height: "50px",
                  }}
                ></Img>
              )
            })}
          </div>
          <div className="z-1 relative">{children}</div>
        </main>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
